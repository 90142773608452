<template>
  <div class="container">
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>基本信息</div>
    </div>
    <div class="table-info">
      <div class="info-row">
        <div class="info-list">
          <div class="info-head">活动时间：</div>
          <div class="info-details">{{ form.actTime }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">参团人数：</div>
          <div class="info-details">{{ form.group_num }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">拼团有效期：</div>
          <div class="info-details">{{ form.effective_time }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">自动成团：</div>
          <div class="info-details">
            <span>{{ form.is_auto ? "开启" : "关闭" }}</span
            ><span style="margin-left: 5px;" v-if="form.is_auto">{{
              `距离成团${form.auto_time_time}自动成团`
            }}</span>
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">限购：</div>
          <div class="info-details">
            {{ form.is_max ? `${form.max_num}人` : "不限购" }}
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">凑团：</div>
          <div class="info-details">
            {{ form.is_group ? "开启" : "不开启" }}
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">标签显示：</div>
          <div class="info-details">{{ form.group_label || "-" }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">活动提示：</div>
          <div class="info-details">
            {{
              form.show_info
                ? "活动未开始时，提前24小时在商品详情页提示用户"
                : ""
            }}
          </div>
        </div>
      </div>
      <div class="info-list" style="display: flex;">
        <div style="min-width: 70px;">活动商品：</div>
        <div class="info-details">
          <el-table
            ref="multipleTable"
            :data="form.goods_list"
            tooltip-effect="dark"
            style="width: 100%;min-width: 800px"
            :header-cell-style="{ background: '#F5F5F5' }"
          >
            <el-table-column label="商品" prop="goods_name"> </el-table-column>
            <el-table-column label="规格" prop="sku_name"> </el-table-column>
            <el-table-column label="价格" prop="price"> </el-table-column>
            <el-table-column label="当前库存" prop="stock"> </el-table-column>
            <el-table-column label="拼团价" prop="promote_price">
            </el-table-column>
            <el-table-column label="活动限量" prop="limit_sale">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      group_id: this.$route.query.group_id,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      const data = {
        group_id: this.group_id,
      };
      this.axios
        .post("/store/Shopactivity/getGroupActivityInfo", data)
        .then((res) => {
          this.form = res.data.info;
          this.form.actTime = `${this.dayjs(
            res.data.info.start_time * 1000
          ).format("YYYY-MM-DD hh:mm:ss")}至${this.dayjs(
            res.data.info.end_time * 1000
          ).format("YYYY-MM-DD HH:mm:ss")}`;
          this.form.effective_time = `${Math.floor(
            res.data.info.effective_time / 60
          )}时${res.data.info.effective_time % 60}分`;

          this.form.auto_time_time = `${Math.floor(
            res.data.info.auto_time / 60
          )}时${res.data.info.auto_time % 60}分`;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.table-info {
  width: 80%;
  padding: 10px 11px;
  .info-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .info-list {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}
</style>
